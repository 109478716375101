import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import { toCamelCase, telephoneStrip } from "../../utils/utils"

import { func } from "prop-types"
import ImageMeta from "../ImageMeta"
import SocialIcon from "./SocialIcon"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import CopyToClipboard from "../CopyToClipboard"

var classNames = require("classnames")
var widont = require("widont")

const socialIconFillColor = "#fff"

export default class ComFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleFooter = this.toggleFooter.bind(this)
  }

  toggleFooter() {}

  render() {
    var comfooterClass = classNames({
      "com-footer": true,
      "color-back": true
    })

    return (
      <StaticQuery
        query={graphql`
          query footerMyQuery {
            allLocationsJson(sort: { fields: locationData___order }) {
              nodes {
                title
                locationData {
                  ...locationDataFields
                }
                pageData {
                  title
                  searchHeading
                  hasThisPage
                  ...locationPageURL
                  layout_3 {
                    formSection {
                      hasForm
                      seamlessShortcode
                    }
                  }
                }
              }
            }
            footerJson {
              footerItems {
                blurbText
                blurbTextEs
                logo
              }
            }
            site {
              siteMetadata {
                fullPracticeName
              }
            }
          }
        `}
        render={(data) => {
          let siteMetadata = data.site.siteMetadata
          let locationOne = data.allLocationsJson.nodes[0].locationData

          let footerItems = data.footerJson.footerItems

          const locations = data.allLocationsJson.nodes.map((data) => {
            return {
              ...data.locationData,
              form: data.pageData.layout_3.formSection,
              hasThisPage: data.pageData.hasThisPage,
              title: data.pageData.title,
              searchHeading: data.pageData.searchHeading
            }
          })

          // Input location order number to get specific location from array, not array index.
          // useLocation(1) will return locations[0]
          // useLocation(3) will return locations[2]
          const useLocation = (num) => {
            if (!num) return locations[0]

            let index = num - 1

            // if array index doesn't exist, return first index
            if (!locations[index]) return locations[0]

            return locations[index]
          }

          return (
            <>
              <footer
                id={this.props.pageTitle}
                style={{ position: "relative" }}
                className={
                  comfooterClass + " is-hidden-mobile " + this.props.pageTitle
                }
              >
                <div className="columns is-centered is-vcentered">
                  <div className="column is-offset-6-tablet is-offset-8-desktop footer-logo-blurb">
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={footerItems.logo}
                      className="logo"
                      width="auto"
                      noLazyload
                      responsive
                    ></ImageMeta>
                    {this.props.language === "es" ? (
                      <h5 className="footer-blurb-text">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: footerItems.blurbTextEs
                          }}
                        ></span>
                      </h5>
                    ) : (
                      <h5 className="footer-blurb-text">
                        {footerItems.blurbText}
                      </h5>
                    )}
                  </div>
                  <div className="column is-6-tablet is-8-desktop"></div>
                </div>
                <div className="columns">
                  <div className="column is-2"></div>
                  {/* START LOCATION COLUMNS */}
                  <div className="column">
                    <OfficeSection
                      location={useLocation(1)}
                      language={this.props.language}
                    />
                    <div className="footer-hours-social">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={useLocation(1).locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div>

                  <div className="column">
                    <OfficeSection
                      location={useLocation(2)}
                      language={this.props.language}
                    />

                    <div className="footer-hours-social">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={useLocation(2).locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div>

                  {/* <div className="column">
                    <OfficeSection
                      location={useLocation(3)}
                      language={this.props.language}
                    />
                    <div className="footer-hours-social">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={useLocation(3).locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div> */}

                  {/* <div className="column is-narrow">
                    <OfficeSection
                      location={useLocation(4)}
                      language={this.props.language}
                    />
                    <div className="footer-hours-social">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={useLocation(4).locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div> */}

                  <div className="column is-narrow footer-contact">
                    <div className="office-section">
                      <h7>
                        {this.props.language === "es"
                          ? "Contáctenos"
                          : "Contact Us"}
                      </h7>
                      {/* <a href={`mailto:${locations[0].email}`} title="Send us a message">{locations[0].email}</a> */}
                      <p className="small">
                        {useLocation(1).locationName} Office:{" "}
                        <a
                          href={`tel:+1${telephoneStrip(useLocation(1).phone)}`}
                          title="Call us"
                        >
                          {useLocation(1).phone}
                        </a>
                      </p>
                      <p className="small">
                        {useLocation(2).locationName} Office:{" "}
                        <a
                          href={`tel:+1${telephoneStrip(useLocation(2).phone)}`}
                          title="Call us"
                        >
                          {useLocation(2).phone}
                        </a>
                      </p>
                    </div>
                  </div>

                  {/* END LOCATION COLUMNS */}
                  <div className="column is-2"></div>
                </div>

                <div
                  className="column footer-social"
                  style={{ marginTop: "20px" }}
                >
                  <div className="columns">
                    {locationOne.socialAccounts &&
                      locationOne.socialAccounts.map((item, i) => (
                        <React.Fragment key={i}>
                          <p className={`small social-icon ${item.platform}`}>
                            <a
                              aria-label="open link in new browser tab"
                              href={item.link}
                              target="_blank"
                              title={item.platform}
                            >
                              <SocialIcon
                                fillColor={socialIconFillColor}
                                socialAccount={item.platform}
                              />
                            </a>
                          </p>
                        </React.Fragment>
                      ))}
                  </div>
                  <div className="column is-2" />
                </div>

                <div className="columns copyright-socials">
                  <div className="column is-offset-2-desktop is-offset-2-tablet is-16 sub-footer-copyright">
                    <p className="footer nuvo-brand">
                      © {new Date().getFullYear()}{" "}
                      {siteMetadata.fullPracticeName}. <br />
                      All rights reserved.
                    </p>
                  </div>
                  <div className="column footer-social">
                    <div className="columns footer-social-icons"></div>
                  </div>
                  <div className="column is-2"></div>
                </div>

                <div className="columns">
                  <div className="column is-10 is-offset-2-desktop is-offset-2-tablet sub-footer-copyright">
                    <p className="footer nuvo-brand-inverse">
                      © {new Date().getFullYear()}{" "}
                      {siteMetadata.fullPracticeName}. <br />
                      All rights reserved.
                    </p>
                    <p
                      className="nuvo-brand footer"
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.language === "es"
                            ? '<a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Sitio Web y Producción de Video</a> por Nuvolum'
                            : '<a class="hover--underline" href="https://nuvolum.com" target="_blank" title="Website, Video & Oral Surgery Marketing by Nuvolum" style="font-weight: bold;">Website, Video & Oral Surgery Marketing</a> by Nuvolum'
                      }}
                    ></p>
                    {this.props.language === "en" && (
                      <p className="nuvo-brand footer">
                        <a
                          style={{ fontWeight: "bold" }}
                          href="https://getwuwta.com/"
                          title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                          target="_blank"
                        >
                          Patient experience powered by WUWTA™
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="column">
                    <div className="columns sub-footer-statements">
                      {this.props.language !== "es" &&
                        locations[0].extraLinks &&
                        locations[0].extraLinks[0].statements.map((item, i) => (
                          <div key={i} className="column is-narrow">
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </div>
                        ))}
                      {this.props.language === "es" &&
                        locations[0].extraLinks &&
                        locations[0].extraLinks[0].statements.map((item, i) => (
                          <React.Fragment key={i}>
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  <div className="column is-2"></div>
                </div>
              </footer>

              {/* Mobile footer */}
              <footer
                id={this.props.pageTitle}
                className={
                  comfooterClass + " is-hidden-tablet " + this.props.pageTitle
                }
              >
                <div className="columns is-centered is-vcentered is-mobile">
                  <div className="column is-2"></div>
                  <div className="column is-8">
                    <ImageMeta
                      className="footer-mobile-logo"
                      cloudName="nuvolum"
                      publicId={footerItems.logo}
                      width="auto"
                      noLazyload
                      responsive
                    ></ImageMeta>
                  </div>

                  <div className="column">
                    {this.props.language === "es" ? (
                      <h5 className="footer-blurb-text">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: footerItems.blurbTextEs
                          }}
                        ></span>
                      </h5>
                    ) : (
                      <h5 className="footer-blurb-text">
                        {footerItems.blurbText}
                      </h5>
                    )}
                  </div>
                  <div className="column is-2"></div>
                </div>

                <div className="column">
                  <div className="columns has-text-centered">
                    {/* {!locations[1] && <div className="column">
                                            {!locations[1] ?
                                                <h7>{this.props.language === "es" ? "Ubicación" : "Location"}</h7>
                                                :
                                                <h7>{this.props.language === "es" ? "Ubicación" : locations[0].locationName + " Office"}</h7>
                                            }
                                            <p className="small footer-location-address"><a target="_blank" href={locations[0].googlePlaceLink} title={locations[0].locationName}>{locations[0].streetNumber + " "}
                                                {locations[0].streetName}<br />
                                                {locations[0].cityName}
                                                {", " + locations[0].stateAbbr + " "}
                                                {locations[0].cityZip}</a></p>
                                            {locations[1] && <><h7>{this.props.language === "es" ? "Ubicación" : locations[1].locationName + " Office"}</h7>
                                                <p className="small footer-location-address"><a target="_blank" href={locations[1].googlePlaceLink} title={locations[1].locationName}>{locations[1].streetNumber + " "}
                                                    {locations[1].streetName}<br />
                                                    {locations[1].cityName}
                                                    {", " + locations[1].stateAbbr + " "}
                                                    {locations[1].cityZip}</a></p>
                                            </>
                                            }
                                        </div>} */}
                    <FourFooterLocations
                      locations={locations}
                      language={this.props.language}
                    />
                    <div className="column">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={useLocation(1).locationHours}
                        language={this.props.language}
                      />

                      {/* <h7>{this.props.language === "es" ? "Contáctenos" : "Contact Us"}</h7>
                                            <a style={{color: "white"}} href={`mailto:${locations[0].email}`} title="Send us a message">{locations[0].email}</a>
                                            <p className="small"><a  style={{color: "white"}} href={`tel:+1${telephoneStrip(locations[0].phone)}`} title="Call us">{locations[0].phone}</a></p>  */}
                    </div>
                  </div>
                  <div className="columns ">
                    {/* <div className="column footer-contact">
                                            <h7>{this.props.language === "es" ? "Contáctenos" : "Contact Us"}</h7>
                                            <p className="small"><a href={`mailto:${locations[0].email}`} title="Email us">{locations[0].email}</a><br /><a href={`mailto:${locations[1].email}`} title="Email us">{locations[1].email}</a><br /><a href={`mailto:${locations[2].email}`} title="Email us">{locations[2].email}</a><br /><a href={`mailto:${locations[2].email}`} title="Email us">{locations[2].email}</a></p>
                                            <p className="small"><Link to={
                                                this.props.language === "es" ? "/es/comuniquese-con-nosotros/" : "/contact-us/"
                                            }>
                                                this.props.language === "es" ? "Mandanos Mensaje" : "Send a Message"
                                            </Link></p>
                                            <p className="small"><a href={"tel:+" + locations[0].phone} title="Call us">{locations[0].phone}</a></p>
                                            {locations[0].chatLink &&
                                                <p className="small">
                                                    <Link to={this.props.language === "es" ? "/contact-us/" : "/contact-us/"}>{this.props.language === "es" ? "Mandanos un Mensaje" : locations[0].chatLink}</Link>
                                                </p>}
                                        </div> */}
                    <div
                      className="column footer-social"
                      style={{ marginTop: "20px" }}
                    >
                      <div className="columns">
                        {locations[0].socialAccounts &&
                          locations[0].socialAccounts.map((item, i) => (
                            <React.Fragment key={i}>
                              <p
                                className={`small social-icon ${item.platform}`}
                              >
                                <a
                                  aria-label="open link in new browser tab"
                                  href={item.link}
                                  target="_blank"
                                  title={item.platform}
                                >
                                  <SocialIcon
                                    fillColor={socialIconFillColor}
                                    socialAccount={item.platform}
                                  />
                                </a>
                              </p>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-2"></div>

                <div className="columns is-desktop footer-bottom has-text-centered">
                  <div className="column is-4"></div>
                  <div className="column" style={{ marginBottom: "16px" }}>
                    <div className="columns sub-footer-statements">
                      {this.props.language !== "es" &&
                        locations[0].extraLinks &&
                        locations[0].extraLinks[0].statements.map((item, i) => (
                          <div key={i} className="column is-narrow">
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </div>
                        ))}
                      {this.props.language === "es" &&
                        locations[0].extraLinks &&
                        locations[0].extraLinks[0].statements.map((item, i) => (
                          <React.Fragment key={i}>
                            <p className="footer">
                              <Link to={`${item.pageLink}`} title={item.title}>
                                {item.linkText}
                              </Link>
                            </p>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  <div className="column is-7 sub-footer-copyright">
                    <p className="footer">
                      © {new Date().getFullYear()}{" "}
                      {siteMetadata.fullPracticeName}. All rights reserved.
                    </p>
                    <p
                      style={{ marginTop: "1rem" }}
                      className="nuvo-brand footer"
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.language === "es"
                            ? '<a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Sitio Web y Producción de Video</a> por Nuvolum'
                            : '<a href="https://nuvolum.com" target="_blank" title="Website, Video & Oral Surgery Marketing by Nuvolum">Website, Video & Oral Surgery Marketing</a> by Nuvolum'
                      }}
                    ></p>
                    {this.props.language === "en" && (
                      <p className="nuvo-brand footer">
                        <a
                          style={{ fontWeight: "bold" }}
                          href="https://getwuwta.com/"
                          title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                          target="_blank"
                        >
                          Patient experience powered by WUWTA™
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-2"></div>
                </div>
              </footer>
            </>
          )
        }}
      />
    )
  }
}

function OfficeSection(props) {
  return (
    <div className="office-section">
      <div className="footer-location-title">
        <h7>
          {props.location.hasThisPage && props.language === "en" ? (
            <a
              href={`/${props.location.title}`}
              title={props.location.searchHeading}
            >
              {props.location.locationName}
            </a>
          ) : (
            <>{props.location.locationName}</>
          )}
        </h7>
      </div>

      <div className="small footer-location-address">
        <a
          title="Get directions"
          aria-label="open link in new browser tab"
          href={props.location.googlePlaceLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.location.streetNumber + " "}
          {props.location.streetName}
          <br />
          {props.location.cityName}
          {", " + props.location.stateAbbr + " "}
          {props.location.cityZip}
        </a>

        {/* <p className="small">
          <a href={"tel:+" + props.location.phone} title="Call us">
            {props.location.phone}{" "}
          </a>
        </p> */}
        <br />
        <CopyToClipboard
          language={props.language}
          email={props.location.email}
        />

        {/* {props.language !== "es" && (
          // <p className="small"><a href={`/contact-us/?f=${toCamelCase(props.location.locationName)}`}>Send a Message</a></p>
          <p className="small">
            <Link
              to="/contact-us/"
              title="Send us a message"
              state={{
                formId: props.location.form.seamlessShortcode,
                locationName: props.location.locationName,
              }}
            >
              Send a Message
            </Link>
          </p>
        )} */}
      </div>
    </div>
  )
}

// Mobile locations
function FourFooterLocations({ locations, language }) {
  return (
    <div className="column footer-location-title">
      {locations.length > 0 &&
        locations.map((item, i) => (
          <React.Fragment key={i}>
            <h7>
              {item.hasThisPage && language === "en" ? (
                <Link to={`/${item.title}`} title={item.searchHeading}>
                  {item.locationName}
                </Link>
              ) : (
                <>{item.locationName}</>
              )}
            </h7>

            <div className="small footer-location-address">
              <a
                title="Get directions"
                aria-label="open link in new browser tab"
                target="_blank"
                href={item.googlePlaceLink}
              >
                {item.streetNumber + " "}
                {item.streetName}
                <br />
                {item.cityName}
                {", " + item.stateAbbr + " "}
                {item.cityZip}
              </a>

              <br />

              <a href={`tel:+1${item.phone.replace(/\D/g, "")}`}>
                {item.phone}
              </a>

              <br />

              <a href={`mailto:${item.email}`}>{item.email}</a>

              {/* {props.language !== "es" && (
              <>
                <br />
                <p className="small">
                  <Link
                    to="/contact-us/"
                    title="Send a message"
                    state={{
                      formId: item.form.seamlessShortcode,
                      locationName: item.locationName,
                    }}
                  >
                    Send a Message
                  </Link>
                </p>
              </>
            )} */}
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}
