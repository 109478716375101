/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, pathname, schemaPage, robots, preload, youtubeSchemas }) {
  const { site, schema} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        schema: allDataJson(filter: {schemaGeneral: {ne: null}}) {
          nodes {
            schemaGeneral
          }
          }
      }
    `
  )

  var schemaGeneral;

  const metaSchemaPage = schemaPage || null

  schemaGeneral = schema.nodes[0].schemaGeneral

  const metaRobots = robots;

  const metaDescription = description || site.siteMetadata.description

  const siteUrl = site.siteMetadata.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://res.cloudinary.com/nuvolum/image/upload/w_1200,h_627,q_99/v1626887738/SVOS/DEV/social-image.jpg`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `627`,
        },
        {
          property: `og:image:alt`,
          content: `Napa Sonoma Oral Surgery & Dental Implants`,
        },
        {
          property: `og:url`,
          content: `${siteUrl}${pathname || "/"}`,
        },
        {
          name: `robots`,
          content: metaRobots
        }
      ].concat(meta)}
    >
      {
        youtubeSchemas && youtubeSchemas.length > 0 && 
          youtubeSchemas.map((schema, i) => 
          <script key={"schema-" + i} type="application/ld+json">{schema}</script>
          )
      }
      {
        preload &&
        preload.map((item) => 
          <link key={item.href} rel="preload" as={item.as} href={item.href} />
        )
      }
      <script type="application/ld+json">{metaSchemaPage || metaSchemaPage != null ? metaSchemaPage : schemaGeneral}</script>
      
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  schemaPage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
